import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/Layout"
import BlogCard from "../components/BlogCard"
import SiteMetadata from "../components/SiteMetadata"
import ContactBanner from "../components/ContactBanner"

const BlogIndex = ({ data }) => {
  const posts = data.allContentfulBlogPost.edges

  const PostWrap = ({ children }) => (
    <div className="container flex flex-col w-screen mx-auto mb-20 lg:flex-row justify-evenly">
      {children}
    </div>
  )

  return (
    <Layout>
      <SiteMetadata
        title="Zurly Blog Posts"
        description="Supercharging your fundraising campaign or membership partification with virtual discount cards"
      />
      <div className="bg-customlightgray">
        <h1 className="pt-32 my-0 text-center">Zurly Resource Guides</h1>
        <PostWrap>
          {posts.map(({ node }) => {
            return (
              <BlogCard
                title={node.title}
                featuredImage={node.featuredImage}
                link={node.slug}
                date={node.createdAt}
                body={node.body}
                author={node.author}
                slug={node.slug}
              />
            )
          })}
        </PostWrap>
        <ContactBanner />
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: [createdAt], order: DESC }
    ) {
      edges {
        node {
          author
          title
          body {
            childMarkdownRemark {
              excerpt(pruneLength: 200)
              html
            }
          }
          createdAt(formatString: "MMMM DD, YYYY")
          featuredImage {
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          slug
        }
      }
    }
  }
`
